* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.container {
  max-width: 110rem;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
}

/* ================================================================================================================================
Header
=================================================================================================================================== */

body {
  font-family: "Poppins", sans-serif;
  /* font-family: 'League Spartan', sans-serif; */
  color: #212112;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: 7%;
  background-color: #fff;
  overflow-x: hidden;
  transition: all 200ms linear;
}

.navbar-expand-md {
  justify-content: center;
}

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 10px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  padding: 0;
}

.navbar-brand img {
  height: 40px;
  width: auto;
  /* display: block; */
  margin: auto;
}



/* ===============================================================================================================================
Card
================================================================================================================================= */

.product-card {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 10px;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;

}

.car_modification {
  width: 100%;
  margin: auto;
  max-width: 60%;
  overflow: hidden;
}

.product-details {
  height: 50px;
  color: black;
  border-radius: 10px;
}

.product-details a {
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 18px;
  color: black;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-details a:hover {
  color: #363062;
}

.product-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -40px, rgba(0, 0, 0, 0.3) 0px 30px 60px -40px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

/* ================================================================================================================================
    Sub Details
=================================================================================================================================== */
.table_heder th {
  background-color: #363062;
  color: white;
}

.subproduct-details {
  padding-left: 15px;
  padding-right: 15px;
  height: 100px;
  color: black;
  border-radius: 10px;
  overflow: hidden;
}

.subproduct-details a {
  font-weight: 500;
  font-size: 13px;
  display: block;
  color: black;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subproduct-details a:hover {
  color: #363062;
}

/* ======================================================================================================================== 
CarpartsDetails
============================================================================================================================*/
.fig-number {
  color: black;
  font-size: 11px;
  padding: 1px 4px;
  font-weight: 700;
  border: 1px solid black;
}

.fig-number.hovered,
.item_1.hovered {
  background: red !important;
  color: #000;
}

.item_1.hovered td {
  background: #363062;
  border: 1px solid #fff;
  color: #fff;
}

/* ==========================================================================================================================
Loader
============================================================================================================================== */
.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.car__body {
  -webkit-animation: shake 0.2s ease-in-out infinite alternate;
  animation: shake 0.2s ease-in-out infinite alternate;
}

.car__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  -webkit-animation: line 0.8s ease-in-out infinite;
  animation: line 0.8s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.car__line--top {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.car__line--middle {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.car__line--bottom {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes shake {
  0% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(3%);
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(3%);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}

/* ==================================================================================================================
                 404page
==================================================================================================================== */
main {
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Merriweather Sans", sans-serif;
}

main #errorText {
  font-size: 22px;
  margin: 14px 0;
}

main #errorLink {
  font-size: 20px;
  padding: 12px;
  border: 1px solid;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

main #errorLink:hover,
main #errorLink:active {
  color: #fff;
  background: #000;
}

main #g6219 {
  transform-origin: 85px 4px;
  -webkit-animation: an1 12s 0.5s infinite ease-out;
  animation: an1 12s 0.5s infinite ease-out;
}

@-webkit-keyframes an1 {
  0% {
    transform: rotate(0);
  }

  5% {
    transform: rotate(3deg);
  }

  15% {
    transform: rotate(-2.5deg);
  }

  25% {
    transform: rotate(2deg);
  }

  35% {
    transform: rotate(-1.5deg);
  }

  45% {
    transform: rotate(1deg);
  }

  55% {
    transform: rotate(-1.5deg);
  }

  65% {
    transform: rotate(2deg);
  }

  75% {
    transform: rotate(-2deg);
  }

  85% {
    transform: rotate(2.5deg);
  }

  95% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes an1 {
  0% {
    transform: rotate(0);
  }

  5% {
    transform: rotate(3deg);
  }

  15% {
    transform: rotate(-2.5deg);
  }

  25% {
    transform: rotate(2deg);
  }

  35% {
    transform: rotate(-1.5deg);
  }

  45% {
    transform: rotate(1deg);
  }

  55% {
    transform: rotate(-1.5deg);
  }

  65% {
    transform: rotate(2deg);
  }

  75% {
    transform: rotate(-2deg);
  }

  85% {
    transform: rotate(2.5deg);
  }

  95% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0);
  }
}


/* ===================================================== */
.breadcrumb-link .breadcrumb-link-label {
  color: #d64444;
  padding: 0px 5px;
}

.breadcrumb-link .breadcrumb-link-label:hover {
  color: black;
  /* background-color: #EAEAEA; */
}

.breadcrumb-link .breadcrumb-link-label {
  position: relative;
  text-decoration: none;
}

.breadcrumb-link .breadcrumb-link-label::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #d64444;
  transition: width 0.3s ease;
  transform: translateX(-50%);
}

.breadcrumb-link .breadcrumb-link-label:hover::after {
  width: 100%;
}


/* ===================================================================================================
Responsive CSS
=========================================================================================================== */

.car_modification {
  width: 100%;
  margin: auto;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 576px) {
  .fig-number {
    font-size: 8px;
    padding: 1px 3px;
  }
}

@media (min-width: 768px) {
  .car_modification {
    max-width: 100%;
  }

  .fig-number {
    font-size: 11px;
    padding: 1px 3px;
  }
}

@media (min-width: 990px) {
  .car_modification {
    max-width: 80%;
  }
}

@media (min-width: 1200px) {
  .car_modification {
    max-width: 70%;
  }
}

@media (min-width: 1400px) {
  .car_modification {
    max-width: 60%;
  }

  .fig-number {
    padding: 2px 4px;
  }
}

/*  */
.boxes {
  height: 100px;
  width: 150px;
  background-color: coral;
  margin: 5px 10px;
  cursor: pointer;
  transition: all 1s;
  /* lets make it smooth */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: white;
  padding: 10px;
}

.move-up:hover {
  /* background-color: crimson; */
  transition: all 0.5s;
  transform: translateY(-10px);
}

:root {
  --button-padding: 10px 20px;
  --button-bg-color: #007bff;
  --button-text-color: #ffffff;
  --button-border-radius: 8px;
}

.button_fgh {
  padding: var(--button-padding);
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border-radius: var(--button-border-radius);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}